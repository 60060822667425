import { Link, navigate } from "gatsby"
import { useAdminAcceptInvite } from "medusa-react"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { decodeToken } from "react-jwt"
import Button from "../components/fundamentals/button"
import LongArrowRightIcon from "../components/fundamentals/icons/long-arrow-right-icon"
import MedusaIcon from "../components/fundamentals/icons/medusa-icon"
import MedusaVice from "../components/fundamentals/icons/medusa-vice"
import SigninInput from "../components/molecules/input-signin"
import SEO from "../components/seo"
import LoginLayout from "../components/templates/login-layout"
import useNotification from "../hooks/use-notification"
import { getErrorMessage } from "../utils/error-messages"
import logoIcon from "../assets/images/logo.png"

type formValues = {
  password: string
  repeat_password: string
  first_name: string
  last_name: string
  token: string
}

const InvitePage = ({ location }) => {

  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const [ready, setReady] = useState(false)

  const { register, handleSubmit, formState } = useForm<formValues>({
    defaultValues: {
      first_name: "",
      last_name: "",
      password: "",
      repeat_password: "",
      token: ""
    },
  })

  const accept = useAdminAcceptInvite()
  const notification = useNotification()

  const handleAcceptInvite = (data: formValues) => {
    setPasswordMismatch(false)

    if (data.password !== data.repeat_password) {
      setPasswordMismatch(true)
      return
    }

    accept.mutate(
      {
        token: data.token,
        user: {
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
        },
      },
      {
        onSuccess: () => {
          navigate("/login")
        },
        onError: (err) => {
          notification("失败", getErrorMessage(err), "error")
        },
      }
    )
  }

  useEffect(() => {
    if (
      formState.dirtyFields.token &&
      formState.dirtyFields.password &&
      formState.dirtyFields.repeat_password &&
      formState.dirtyFields.first_name &&
      formState.dirtyFields.last_name
    ) {
      setReady(true)
    } else {
      setReady(false)
    }
  }, [formState])

  return (
    <>
      <LoginLayout>
        <SEO title="Create Account" />
        <img className="fixed w-[52px] top-[52px] right-[100px]" src={ logoIcon } alt="logo" />
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex min-h-[600px] bg-grey-0 rounded-rounded justify-center">
            <form
              className="flex flex-col py-12 w-full px-[120px] items-center"
              onSubmit={handleSubmit(handleAcceptInvite)}
            >
              <>
                <span className="inter-2xlarge-semibold mt-4 text-grey-90">
                  欢迎加入团队!
                </span>
                <span className="inter-base-regular text-grey-50 mt-2 mb-large">
                  在下面创建您的帐户👇🏼
                </span>
                <SigninInput
                  placeholder="Token (已发至您的邮箱)"
                  name="token"
                  ref={register({ required: true })}
                  autoComplete="given-name"
                />
                <SigninInput
                  placeholder="First name"
                  name="first_name"
                  ref={register({ required: true })}
                  autoComplete="given-name"
                />
                <SigninInput
                  placeholder="Last name"
                  name="last_name"
                  ref={register({ required: true })}
                  autoComplete="family-name"
                />
                <SigninInput
                  placeholder="请输入密码"
                  type={"password"}
                  name="password"
                  ref={register({ required: true })}
                  autoComplete="new-password"
                  maxLength={32}
                />
                <SigninInput
                  placeholder="再次输入密码"
                  type={"password"}
                  name="repeat_password"
                  ref={register({ required: true })}
                  autoComplete="new-password"
                  maxLength={32}
                />
                {passwordMismatch && (
                  <span className="text-rose-50 w-full mt-2 inter-small-regular">
                    两个密码不同
                  </span>
                )}
                <Button
                  variant="primary"
                  size="large"
                  type="submit"
                  className="w-full mt-base"
                  loading={formState.isSubmitting}
                >
                  激活并登录
                </Button>
                <Link
                  to="/login"
                  className="inter-small-regular text-grey-50 mt-large"
                >
                  返回登录
                </Link>
              </>
            </form>
          </div>
        </div>
      </LoginLayout>
    </>
  )
}

export default InvitePage
